import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LandingPageComponent}from'./landing-page/landing-page.component';
import {TermsConditionComponent} from './terms-condition/terms-condition.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
const routes: Routes = [{ path: 'terms-conditions', component:TermsConditionComponent }
,{ path: '', component:LandingPageComponent,pathMatch: 'full' },
{ path: 'privacy-policy', component:PrivacyPolicyComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
