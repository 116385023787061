/// <reference types="@types/googlemaps" />

import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FooterService } from './footer.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
//  import {} from 'googlemaps';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  environment = environment;
  @ViewChild('map', { static: true }) public mapElement: any;
  map: google.maps.Map;
  public isEmpty: any = false;
  private _success = new Subject<string>();
  private _error = new Subject<string>();
  public contactForm: FormGroup;
  public isSaving = false
  public showMsg: boolean = false;
  public isFormSubmited: any;
  public msg: any;
  successMessage = '';
  errorMessage = '';
  staticAlertClosed = false;
  public currentYear = new Date().getFullYear();

  constructor(private fb: FormBuilder, private injector: Injector) { }

  ngOnInit() {
    const mapProperties = {
      center: new google.maps.LatLng(36.778259, -119.417931),
      // zoomControlOptions: { position: 10 },
      controlSize: 20,
      minZoom: 2,
      zoom: 2,
      zoomControl: true,
      disableDefaultUI: true,
      scaleControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    var image = environment.WEB_URL + "assets/images/pin.png"
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(36.778259, -119.417931),
      icon: image
    });


    // const marker2 = new google.maps.Marker({
    //   position: new google.maps.LatLng(-19.491411, 132.550964),

    // });

    marker.setMap(this.map);
    // marker2.setMap(this.map);

    this.contactForm = this.fb.group({
      first_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      last_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      email: [null, [Validators.required, Validators.email]],
      message: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(300)]],

    })
    // setTimeout(() => this.staticAlertClosed = true, 20000);

    this._success.subscribe(message => this.successMessage = message);
    // this._success.pipe(
    //   debounceTime(5000)
    // ).subscribe(() => this.successMessage = '');

    this._error.subscribe(message => this.errorMessage = message);
    // this._error.pipe(
    //   debounceTime(5000)
    // ).subscribe(() => this.errorMessage = '');
  }

  submitForm() {
    this.isFormSubmited = true;


    console.log(this.contactForm.value);
    if (this.contactForm.valid) {
      this.isSaving = true;
      const footerService = this.injector.get(FooterService);
      footerService.submitData(this.contactForm.value).subscribe((response) => {
        console.log('response:', response);
        if (response['body']['status'] === 200) {
          this.isSaving = false;
          this.showMsg = true;
          this.isFormSubmited = false;
          this._success.next(response['body']['message']);
          this.contactForm.reset();
          // this.msg = response['body']['message']
        } else {

          this.isSaving = false;
          this.isFormSubmited = false;
          this._error.next('Something went wrong');

        }
      }, (error) => {
        console.log('error:', error);
        this.isFormSubmited = false;
        this.isSaving = false;
        this._error.next('Something went wrong');
      })
    }
  }
}
